
.autocomplete .suggestions {
  position: absolute;
  top: 60px;
  width: 100%;
  z-index: 2;
  background: #edfcfe;
  list-style-type: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #a4bccf;
  border-top: none;
}

.autocomplete ul {
  padding: 0;
}
.autocomplete .suggestions li {
  font-family: 'Montserrat', serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #09131B;
  margin-bottom: 8px;
  padding-left: 15px;
}

.autocomplete .listItem {
  cursor: pointer;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .autocomplete .suggestions {
      top: 35px;
  }
}
