.loader {
    background: linear-gradient(to bottom right, #ECFFFD, #CBE8FF);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.loader .bodyCont {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
}

.loader .bodyCont h3 {
    color: #09131B;
    text-align: center;
    font-family: 'Montserrat', serif;
    font-size: 20px;
    font-weight: 600;
}

.loader .loaderCont {
    width: 50px;
    margin: auto;
}

.loader .loaderCont .letterCont {
    display: inline-block;
    margin-left: 5px;
    height: 47px;
}

.loader .loaderCont .letterCont img{
    position: absolute;
}

.loader .loaderCont .barsCont{
    width: 24px;
    line-height: 12px;
    display: inline-block;
}

.loader .loaderCont .barsCont span {
    width: 24px;
    height: 6px;
    background: #bad0fa;
    display: inline-block;
    border-radius: 10px;
    float: right;
    margin-top: 6px;
}

.loader .loaderCont .barsCont .bar1 {
    animation-name: bar1;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.loader .loaderCont .barsCont .bar2 {
    animation-name: bar1;
    animation-duration: 1.5s;
    animation-delay: 0.4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.loader .loaderCont .barsCont .bar3 {
    animation-name: bar1;
    animation-duration: 1.5s;
    animation-delay: 0.8s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}


@keyframes bar1 {
    0%   { background: #647bf0; width: 24px }
    50% { background: #bad0fa; width: 16px }
    100% { background: #647bf0; width: 24px }
}

.loader .footCont {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 0;
}

.loader .footCont h4 {
    font-family: Montserrat, serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    color: #09131B;
    margin-bottom: 0;
}

.loader .footCont p {
    font-family: Montserrat, serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    color: #09131B;
    margin-top: 0;
    margin-bottom: 20px;
}
