.alert {
  opacity: 0;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10000;
  color: white;
  width: 90%;
  min-height: 40px;
  font-family: Montserrat, serif;
  font-size: 16px;
  background-color: black;
  border-radius: 0.5rem;
  display: flex;
  transform: translateY(-100px);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 1s ease;
  margin-top: 30px;
  padding: 5px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .alert {
    width: 70%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .alert {
    width: 60%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .alert {
    width: 50%;
  }
}

@media screen and (min-width: 1400px) {
  .alert {
    width: 40%;
  }
}
