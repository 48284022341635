.referralPrograCreate {
  min-height: 100vh;
  min-width: 260px;
}

.referralPrograCreate .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
}

.referralPrograCreate .head .logo {
  width: 35px;
}

.referralPrograCreate .headItem:has(.logo) {
  text-align: center;
}

.referralPrograCreate .head .headItem {
  width: 65px;
}

.referralPrograCreate .starPurple {
  margin-top: 40px;
}

.referralPrograCreate .starBlack {
  margin-top: -20px;
  margin-left: -2px;
}

.referralPrograCreate .loginTitle {
  font-family: 'Montserrat', serif;
  font-weight: 600;
  font-size: 30px;
  color: #232531;
  margin-top: 30px;
}

.referralPrograCreate .loginTitle span {
  background-image: url('images/title-background.png');
  background-repeat: no-repeat;
  background-size: 70% 50%;
  background-position: bottom left 0;
  padding-left: 2px;
}

.referralPrograCreate p {
  font-family: 'Montserrat', serif;
  font-weight: 400;
  font-size: 18px;
  color: #232531;
  margin-top: 10px;
  max-width: 370px;
}

.referralPrograCreate .codeInput {
  width: 100%;
  font-family: 'Montserrat', serif;
  font-weight: 600;
  font-size: 24px;
  border: 1px solid #A4BCCF;
  color: #09131B;
  border-radius: 8px;
  height: 56px;
  margin-top: 2px;
  background: #A3BCCF3D;
  text-align: center;
  letter-spacing: 6px;
}

.referralPrograCreate .codeInput::placeholder {
  transform: translateY(5px);
}

.referralPrograCreate .nextButton {
  display: block;
  margin-top: 26px;
  width: 100%;
  font-family: 'Montserrat', serif;
  font-weight: 700;
  font-size: 16px;
  height: 56px;
  background: #647bf0;
  border: 1px solid #647bf0;
  color: white;
  border-radius: 8px;
  box-shadow: 0px 24px 56px 0px #3E7EDD66;
  transition: opacity 0.6s;
  opacity: 1;
}

.referralPrograCreate .notVisible {
  opacity: 0;
}

.referralPrograCreate .skipButton {
  display: block;
  margin-top: 18px;
  margin-bottom: 25px;
  width: 100%;
  font-family: 'Montserrat', serif;
  font-weight: 700;
  font-size: 16px;
  color: #647bf0;
  height: 56px;
  background: transparent;
  border: 1px solid #647bf0;
  border-radius: 8px;
}


@media screen and (max-height: 530px) {

  .referralPrograCreate .loginTitle {
    font-size: 29px;
    margin-top: 40px;
  }

  .referralPrograCreate p {
    font-size: 17px;
  }

  .referralPrograCreate input {
    font-size: 13px;
  }

  .referralPrograCreate .nextButton {
    font-size: 14px;
  }
}
