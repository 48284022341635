.taskIndex {
  background: #FFFFFF;
  padding: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 24px 56px 0px #3E7EDD33;
  position: relative;
}

.taskIndex .mainImage {
  position: absolute;
  right: 7px;
  top: 3px;
  max-width: 263px;
  width: 55vw;
}

.taskIndex h1 {
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 400;
  color: #7B8D9C;
  margin-top: 5px;
}

.taskIndex .counterCont {
  margin-top: 3px;
}

.taskIndex .counterCont span {
  font-family: Montserrat, serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
}

.taskIndex .quote1 {
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 11px;
  color: #232531;

}

.taskIndex .quote2 {
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-top: -6px;
  color: #232531;
}

.taskIndex .tasksButton {
  display: block;
  margin-top: 18px;
  width: 100%;
  font-family: 'Montserrat', serif;
  font-weight: 700;
  font-size: 15px;
  border: none;
  height: 56px;
  background: #647bf0;
  color: white;
  border-radius: 8px;
  box-shadow: 0px 18px 56px 0px #3E7EDD66;
}

@media screen and (min-width: 350px) and (max-width: 410px) {
  .taskIndex .mainImage {
    top: 9px;
    width: 51vw;
  }

  .taskIndex h1 {
    font-size: 12px;
  }

  .taskIndex .counterCont span {
    font-size: 30px;
  }

  .taskIndex .quote1 {
    font-size: 16px;
    margin-top: 8px;
  }

  .taskIndex .quote2 {
    font-size: 15px;
    margin-top: -8px;
    line-height: 18px;
  }

  .taskIndex .tasksButton {
    font-size: 14px;
    height: 50px;
  }
}

@media screen and (min-width: 305px) and (max-width: 350px) {
  .taskIndex .mainImage {
    top: 7px;
    width: 55vw;
  }

  .taskIndex h1 {
    font-size: 10px;
  }

  .taskIndex .counterCont {
    margin-top: -3px;
  }

  .taskIndex .counterCont span {
    font-size: 26px;
  }

  .taskIndex .quote1 {
    font-size: 14px;
    margin-top: 0px;
    line-height: 18px;
  }

  .taskIndex .quote2 {
    font-size: 13px;
    margin-top: -6px;
    line-height: 16px;
  }

  .taskIndex .tasksButton {
    margin-top: 10px;
    font-size: 13px;
    height: 45px;
  }
}

@media screen and (min-width: 0px) and (max-width: 304px) {
  .taskIndex .mainImage {
    top: 7px;
    width: 50vw;
  }

  .taskIndex h1 {
    font-size: 9px;
    margin-top: 2px;
  }

  .taskIndex .counterCont {
    margin-top: -6px;
  }

  .taskIndex .counterCont span {
    font-size: 22px;
  }

  .taskIndex .quote1 {
    font-size: 12px;
    margin-top: 0px;
    line-height: 16px;
  }

  .taskIndex .quote2 {
    font-size: 11px;
    margin-top: -11px;
    line-height: 14px;
  }

  .taskIndex .tasksButton {
    margin-top: 10px;
    font-size: 13px;
    height: 45px;
  }
}
