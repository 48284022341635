.mainMenu .menuModal {
  height: 100vh;
  width: 100vw;
  background: #FFFFFF;
  position: fixed;
  top: 0;
  pointer-events: all;
  z-index: 98;
  left: -100vw;
  transition: transform 0.2s linear;
  max-width: unset;
}

.mainMenu .active {
  transform: translateX(100vw);
}

.mainMenu .header {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
}

.mainMenu .header .title {
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: #232531;
  margin-top: 5px;
}

.mainMenu .header div .balance {
  display: block;
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #7B8D9C;
}

.mainMenu .header div .price {
  font-family: Montserrat, serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #09131B;
  float: right;
}

.mainMenu .header .rightEmptyBlock {
  width: 40px;
}

.mainMenu .imageMenuClose {
  cursor: pointer;
}

.mainMenu .bonusButton {
  margin-top: 30px;
  margin-bottom: 35px;
  position: relative;
}

.mainMenu .bonusButton button {
  display: block;
  width: 100%;
  font-family: 'Montserrat', serif;
  font-weight: 600;
  font-size: 16px;
  border: none;
  height: 72px;
  color: #FCFCFA;
  border-radius: 8px;
  background: linear-gradient(93.27deg, #232531 -8.36%, #647BF0 124.31%);
}

.mainMenu .bonusButton .firstImg {
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.mainMenu .bonusButton .lastImg {
  position: absolute;
  top: 8px;
  right: 8px;
}

.mainMenu .bonusButton span {
  display: inline-block;
  max-width: 170px;
}

.mainMenu .main {
  overflow: scroll;
  max-height: calc(100vh - 236px);
}

.mainMenu .main::-webkit-scrollbar {
  display: none;
}

.mainMenu .main ul {
  text-decoration: none;
  padding-left: 0;
}

.mainMenu .main ul li {
  font-family: Montserrat, serif;
  font-size: 25px;
  font-weight: 500;
  line-height: 25px;
  color: #09131B;
  display: block;
  margin-bottom: 28px;
  cursor: pointer;
}

.mainMenu .main .activeLink {
  color: #647BF0;
}

.mainMenu .monitorCont {
  display: flex;
  justify-content: space-between;
}

.mainMenu .lb1 {
  margin-top: 5px;
}

.mainMenu .highload1 {
  display: none;
}

.mainMenu .highload1+.lb1, .highload1+.lb1::before, .highload1+.lb1::after {
  transition: all .3s;
}

.mainMenu .highload1+.lb1 {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 24px;
  border-radius: 12px;
  cursor: pointer;
}

.mainMenu .highload1+.lb1::after {
  border-radius: 50%;
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  background: gray;
}

.mainMenu .highload1:checked+.lb1::after {
  left: 24px;
  background-color: #40B93D;
}

.mainMenu .highload1+.lb1 {
  background-color: #ccc;
}

.mainMenu .highload1:checked+.lb1 {
  background-color: #C4FACD;
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .mainMenu .menuModal {
    width: 100vw;
  }

  .mainMenu .bonusButton span {
    max-width: unset;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .mainMenu .menuModal {
    width: 35vw;
  }

  .mainMenu .header .menuImage {
    opacity: 0;
  }

  .mainMenu .imageMenuClose {
    opacity: 0;
  }

  .mainMenu .bonusButton span {
    max-width: 170px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .mainMenu .menuModal {
    width: 28vw;
  }

  .mainMenu .header .menuImage {
    opacity: 0;
  }

  .mainMenu .imageMenuClose {
    opacity: 0;
  }

  .mainMenu .bonusButton span {
    max-width: 170px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .mainMenu .menuModal {
    width: 28vw;
  }

  .mainMenu .header .menuImage {
    opacity: 0;
  }

  .mainMenu .imageMenuClose {
    opacity: 0;
  }

  .mainMenu .bonusButton span {
    max-width: 170px;
  }
}

@media screen and (min-width: 1400px) {
  .mainMenu .menuModal {
    width: 24vw;
  }

  .mainMenu .header .menuImage {
    opacity: 0;
  }

  .mainMenu .imageMenuClose {
    opacity: 0;
  }

  .mainMenu .bonusButton span {
    max-width: 170px;
  }
}
