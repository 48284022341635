.payment {
  min-height: 100vh;
}

.payment .balanceQuote {
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 400;
  color: #7B8D9C;
  margin-top: 30px;
  margin-bottom: 5px;
}

.payment .balanceString {
  font-family: Montserrat, serif;
  font-size: 32px;
  font-weight: 600;
  color: #232531;
}

.payment .balanceReplenish {
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #232531;
  margin-top: 10px;
  margin-bottom: 0;
}

.payment input {
  width: 35%;
  margin-right: 2%;
  display: inline-block;
  font-family: 'Montserrat', serif;
  font-weight: 400;
  font-size: 14px;
  padding-left: 15px;
  border: 1px solid #a4bccf;
  color: #09131B;
  border-radius: 8px;
  height: 56px;
  margin-top: 5px;
  background:  rgba(255, 255, 255, 0.3);
}


.payment .replenishButton {
  width: 62%;
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 25px;
  font-family: 'Montserrat', serif;
  font-weight: 700;
  font-size: 15px;
  border: none;
  height: 56px;
  background: #647bf0;
  color: white;
  border-radius: 8px;
  box-shadow: 0 24px 56px 0 #3E7EDD66;
}

.payment .history {
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 400;
  color: #7B8D9C;
  margin-top: 5px;
}

.payment .transactionCont {
  background: #FFFFFF;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 8px;
  margin-top: 9px;
  row-gap: 6px;
  padding: 20px 20px;
  box-shadow: 0px 24px 56px 0px #3E7EDD33;
}

.payment .transactionCont:last-child {
  margin-bottom: 20px;
}

.payment .transactionCont .price {
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 500;
  color: #232531;
  line-height: 16px;
  white-space: nowrap;
  margin-right: 8px;
}

.payment .transactionCont .source {
  font-family: Montserrat, serif;
  font-size: 12px;
  font-weight: 400;
  color: #7B8D9C;
  white-space: nowrap;
  margin-left: auto;
  margin-right: 25px;
}

.payment .transactionCont .date {
  font-family: Montserrat, serif;
  font-size: 12px;
  font-weight: 400;
  color: #232531;
  line-height: 16px;
  white-space: nowrap;
}

.payment .error {
  font-family: Montserrat, serif;
  font-size: 12px;
  font-weight: 400;
  color: red;
  position: absolute;
  margin-top: -20px;
}
