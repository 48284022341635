.pageStructure .pageContentCont {
  width: 100vw;
  margin-left: 0;
}

.pageStructure .pageContentCont .pageContent  {
  width: 100vw;
  margin: unset;
}

.pageStructure .byCenter {
  margin: auto !important;
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .pageStructure .pageContentCont {
    width: 100vw;
    margin-left: 0;
  }

  .pageStructure .pageContentCont .pageContent  {
    width: 100vw;
    margin: unset;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .pageStructure .pageContentCont {
    width: 65vw;
    margin-left: 35vw;
  }

  .pageStructure .pageContentCont .pageContent {
    width: 48vw;
    margin: auto;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .pageStructure .pageContentCont {
    width: 72vw;
    margin-left: 28vw;
  }

  .pageStructure .pageContentCont .pageContent  {
    width: 50vw;
    margin: auto;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .pageStructure .pageContentCont {
    width: 72vw;
    margin-left: 28vw;
  }

  .pageStructure .pageContentCont .pageContent  {
    width: 50vw;
    margin: auto;
  }
}

@media screen and (min-width: 1400px) {
  .pageStructure .pageContentCont {
    width: 76vw;
    margin-left: 24vw;
  }

  .pageStructure .pageContentCont .pageContent  {
    width: 40vw;
    margin: auto;
  }
}
