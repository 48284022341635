.emptyEntities {
  position: relative;
  margin: auto;
  width: 100%;
}

.emptyEntities img {
  display: block;
  margin: auto;
  width: 173px;
}

.emptyEntities h1 {
  margin-top: 10px;
  font-family: Montserrat, serif;
  font-size: 24px;
  font-weight: 500;
  color: #232531;
  text-align: center;
}

.emptyEntities p {
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 700;
  color: #647BF0;
  text-align: center;
}



@media  screen and (max-height: 485px) {
  .emptyEntities img {
    display: none;
  }

  .emptyEntities h1 {
    font-size: 20px;
  }

  .emptyEntities p {
    margin-top: 0;
    font-size: 12px;
  }
}

@media screen and (min-height: 485px) and (max-height: 640px) {
  .emptyEntities img {
    width: 140px;
  }

  .emptyEntities h1 {
    font-size: 20px;
  }

  .emptyEntities p {
    margin-top: 0;
    font-size: 12px;
  }
}
