.referralProgram {
  background: #FFFFFF;
  padding: 16px;
  margin-top: 20px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 24px 56px 0px #3E7EDD33;
}

.referralProgram .mainImage {
  position: relative;
  right: -24px;
  top: -35px;
  float: right;
  width: 63%;
  max-width: 220px;
}

.referralProgram .leftCont {
  position: absolute;
}

.referralProgram h1 {
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 400;
  color: #7B8D9C;
  margin-top: 5px;
}

.referralProgram .counterCont {
  margin-top: 3px;
}
.referralProgram .counterCont span {
  font-family: Montserrat, serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
}

.referralProgram .counterCont img {
  position: relative;
  display: inline-block;
  top: -7px;
  margin-left: 18px;
  cursor: pointer;
}

.referralProgram p {
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  width: 230px;
  margin-top: 11px;
}

.referralProgram .codeCont {
  background: #A3BCCF3D;
  border: 1px solid #A4BCCF;
  border-radius: 8px;
  height: 56px;
  margin-top: 150px;
  text-align: center;
}

.referralProgram .codeCont span {
  font-family: Montserrat, serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 56px;
  color: #09131B;
  letter-spacing: 2px;
}

.referralProgram .copyButton {
  display: block;
  margin-top: 16px;
  width: 100%;
  font-family: 'Montserrat', serif;
  font-weight: 700;
  font-size: 15px;
  border: none;
  height: 56px;
  background: #647bf0;
  color: white;
  border-radius: 8px;
  box-shadow: 0px 18px 56px 0px #3E7EDD66;
}

@media screen and (min-width: 350px) and (max-width: 410px) {
  .referralProgram .mainImage {
    top: -35px;
    width: 64%;
    max-width: 206px;
  }

  .referralProgram h1 {
    font-size: 12px;
  }

  .referralProgram .counterCont span {
    font-size: 30px;
  }

  .referralProgram p {
    font-size: 16px;
    width: 205px;
    margin-top: 8px;
  }

  .referralProgram .codeCont {
    height: 50px;
    margin-top: 140px;
  }

  .referralProgram .codeCont span {
    font-size: 22px;
    line-height: 50px;
    letter-spacing: 2px;
  }

  .referralProgram .copyButton {
    font-size: 14px;
    height: 50px;
  }
}

@media screen and (min-width: 305px) and (max-width: 350px) {
  .referralProgram .mainImage {
    top: -27px;
    width: 165px;
  }

  .referralProgram h1 {
    font-size: 10px;
  }

  .referralProgram .counterCont {
    margin-top: -3px;
  }

  .referralProgram .counterCont span {
    font-size: 26px;
  }

  .referralProgram .counterCont img {
    top: -6px;
    width: 21px;
  }

  .referralProgram p {
    font-size: 14px;
    width: 180px;
    margin-top: 0px;
  }

  .referralProgram .codeCont {
    height: 45px;
    margin-top: 120px;
  }

  .referralProgram .codeCont span {
    font-size: 20px;
    line-height: 45px;
    letter-spacing: 2px;
  }

  .referralProgram .copyButton {
    margin-top: 10px;
    font-size: 13px;
    height: 45px;
  }
}

@media screen and (min-width: 0px) and (max-width: 304px) {
  .referralProgram .mainImage {
    top: -27px;
    width: 63%;
    max-width: 154px;
  }

  .referralProgram h1 {
    font-size: 9px;
    margin-top: 2px;
  }

  .referralProgram .counterCont {
    margin-top: -6px;
  }

  .referralProgram .counterCont span {
    font-size: 22px;
  }

  .referralProgram .counterCont img {
    top: -4px;
    width: 18px;
    margin-left: 14px;
  }

  .referralProgram p {
    font-size: 12px;
    width: 150px;
    margin-top: 0px;
    line-height: 17px;
  }

  .referralProgram .codeCont {
    height: 45px;
    margin-top: 105px;
  }

  .referralProgram .codeCont span {
    font-size: 20px;
    line-height: 45px;
    letter-spacing: 2px;
  }

  .referralProgram .copyButton {
    margin-top: 10px;
    font-size: 13px;
    height: 45px;
  }
}