.versionUpgradeModal .starGreen {
    margin-top: 10px;
}

.versionUpgradeModal .starBlack {
    position: absolute;
    margin-top: -2px;
}

.versionUpgradeModal .orderHead h2 {
    font-family: Montserrat, serif;
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px;
}

.versionUpgradeModal .orderHead p {
    font-family: Montserrat, serif;
    font-size: 16px;
    font-weight: 400;
}

.versionUpgradeModal .orderBody span {
    font-family: Montserrat, serif;
    font-size: 14px;
    font-weight: 400;
    color: #7B8D9C;
    margin-bottom: 4px;
}

.versionUpgradeModal .orderBody p {
    font-family: Montserrat, serif;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    color: #232531;
}

.versionUpgradeModal .orderBody button {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    font-family: 'Montserrat', serif;
    font-weight: 700;
    font-size: 15px;
    border: none;
    background: #647bf0;
    color: white;
    border-radius: 8px;
    box-shadow: 0 24px 56px 0 #3E7EDD66;
    height: 56px
}
