.monitor {
  min-height: 100vh;
}

.monitor .previewImg {
  display: block;
  margin: auto;
  margin-top: 5vh;
}

.monitor, .terminovoImg {
  display: block;
  margin: auto;
}

.monitor .noOrders h1 {
  margin: auto;
  margin-top: 20px;
  width: 90%;
  font-family: Montserrat, serif;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #232531;
}

.monitor .noOrders p {
  margin: auto;
  margin-top: 20px;
  text-align: center;
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 500;
  color: #647BF0;
  max-width: 392px;
}

.monitor .order {
  margin-top: 20px;
  box-shadow: 0px 24px 56px 0px #3E7EDD33;
  background: #FFFFFF;
  border-radius: 10px;
}

.monitor .order .orderHead {
  background: #FFFFFF;
  padding-top: 14px;
  padding-bottom: 0px;
  padding-left: 14px;
  padding-right: 14px;
}

.monitor .order .orderHead .topHolder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.monitor .order .orderHead .topHolder .priceTitle {
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 400;
  color: #7B8D9C;
}

.monitor .order .orderHead .topHolder .serviceName {
  font-family: Montserrat, serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  color: #087A42;
  background: #5DD69B;
  border-radius: 40px;
  padding: 6px 14px;
}

.monitor .order .orderHead p {
  font-family: Montserrat, serif;
  font-size: 20px;
  font-weight: 600;
  color: #232531;
  margin-bottom: 0;
}

.monitor .order .orderSubHead {
  background: #FFFFFF;
  padding: 10px 16px;
  border-bottom: 2px solid #D3EDFD;
  display: flex;
  justify-content: space-between;
}

.monitor .order .orderSubHead span {
  display: block;
}

.monitor .order .orderSubHead .span1 {
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 400;
  color: #7B8D9C;
  margin-bottom: 4px;
}

.monitor .order .orderSubHead .div1 .span2 {
  font-family: Montserrat, serif;
  font-size: 20px;
  font-weight: 600;
  color: #232531;
}

.monitor .order .orderSubHead .div1 .span3 {
  font-family: Montserrat, serif;
  font-size: 12px;
  font-weight: 400;
  color: #7B8D9C;
}

.monitor .order .orderSubHead .div2 .span2 {
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 500;
  color: #232531;
}


.monitor .order .orderBody {
  background: #FFFFFF;
  padding: 14px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.monitor .order .orderBody span {
  display: block;
  position: relative;
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 400;
  color: #7B8D9C;
  margin-bottom: 6px;
}

.monitor .order .orderBody p {
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
  color: #232531;
}

.monitor .order .orderBody p:last-child {
  margin-bottom: 0;
}

.bold {
  font-weight: 500 !important;
}

.monitor .order .acceptButton {
  display: block;
  margin-top: 20px;
  width: 100%;
  font-family: 'Montserrat', serif;
  font-weight: 700;
  font-size: 15px;
  border: none;
  height: 56px;
  background: #647bf0;
  color: white;
  border-radius: 8px;
  box-shadow: 0 24px 56px 0 #3E7EDD66;
}

.monitor .order .declineButton {
  display: block;
  margin: auto;
  margin-top: 10px;
  background: transparent;
  color: #7B8D9C;
  border: solid 1px;
  border-radius: 8px;
  box-shadow: none;
  font-family: 'Montserrat', serif;
  font-weight: 700;
  font-size: 16px;
  height: 56px;
  padding: 0px 15px 0px 15px;
  min-width: 100%;
}

.monitor .order .waitButton {
  display: block;
  margin-top: 20px;
  width: 100%;
  font-family: 'Montserrat', serif;
  font-weight: 700;
  font-size: 15px;
  border: none;
  height: 56px;
  background: #7B8D9C;
  color: white;
  border-radius: 8px;
  box-shadow: 0 24px 56px 0 #3E7EDD66;
}
