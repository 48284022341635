.modal {
  height: 100vh;
  width: 100vw;
  background: rgb(12 15 33/ 0.4);
  backdrop-filter: blur(1.5px);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
  z-index: 99;
  will-change: opacity;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
}

.modal_content {
  padding: 40px;
  background: #ffffff;
  width: 50vw;
  transform: scale(0.5);
  min-width: 505px;
  max-width: 630px;
  border-radius: 8px;
  transition: transform 0.4s;
  will-change: transform;
  overflow-y: scroll;
  max-height: 60vh;
}

.modal_content::-webkit-scrollbar {
  display: none;
}


.modal_content.active {
  transform: scale(1);
}

.closeButtonMobile {
  cursor: pointer;
  position: absolute;
  right: 22px;
  top: 22px;
  display: inline-block
}

.closeButtonMobile img {
  width: 40px;
  height: 40px;
}

@media screen and (max-width: 575px) {
  .modal_content {
    min-width: 93vw;
    padding: 28px;
  }
}

@media screen and (max-width: 400px) {
  .closeButtonMobile {
    right: 16px;
    top: 16px;
  }

  .closeButtonMobile img {
    width: 32px;
    height: 32px;
  }
}
