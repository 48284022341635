.calendar {
  min-height: 100vh;
}

.calendar .mainCont {
  min-height: calc(100vh - 220px)
}

.calendar .allEventsCont {
  margin-top: 40px;
  margin-bottom: 40px;
}

.calendar .eventsPerDaysCont {
  display: flex;
  margin-top: 17px;
}

.calendar .dayCont {
  width: 44px;
  height: 59px;
  border-radius: 8px;
  margin-right: 10px;
  margin-top: 5px;
}

.calendar .dayCont span:first-child{
  display: block;
  margin: auto;
  margin-top: 6px;
  text-align: center;
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 500;
  color: #7B8D9C;
}

.calendar .dayCont span:last-child{
  display: block;
  margin: auto;
  margin-bottom: 4px;
  margin-top: -2px;
  font-family: Montserrat, serif;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: #232531;
}

.calendar .dayContFirst {
  background: #647BF0;
}

.calendar .dayContFirst span:first-child {
  color: #DDF0F4 !important;
}

.calendar .dayContFirst span:last-child {
  color: #FFFFFF !important;
}



.calendar .eventsCont {
  width: 100%;
}

.calendar .eventCont {
  background: #D3EDFD;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 13px 15px;
  margin-bottom: 8px;
  min-height: 70px;
}

.calendar .eventCont .eventBody {
  width: 60%;
}

.calendar .eventCont .eventBody span {
  display: block;
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 400;
  color: #232531;
}

.calendar .eventCont .eventTime span {
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 500;
  color: #232531;
}

.calendar .eventsCont p {
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #7B8D9C;
  margin-top: 14px;
}

.calendar .eventsCont .noOrdersLine {
  border-bottom: 2px solid #D9D9D9;
}

.calendar .eventsCont .noOrdersLine span{
  border-radius: 50%;
  display: inline-block;
  width: 12px;
  height: 12px;
  background: #D9D9D9;
  position: absolute;
  margin-top: -5px;
}

.calendar .AddButton {
  bottom: 20px;
  display: block;
  margin-top: 35px;
  width: 100%;
  font-family: 'Montserrat', serif;
  font-weight: 700;
  font-size: 15px;
  border: none;
  height: 56px;
  background: #647bf0;
  color: white;
  border-radius: 8px;
  box-shadow: 0 24px 56px 0 #3E7EDD66;
  z-index: 1;
}

.calendar h2 {
  font-family: 'Montserrat', serif;
  font-weight: 500;
  font-size: 24px;
  margin-top: 16px;
}

.calendar form input {
  width: 100%;
  font-family: 'Montserrat', serif;
  font-weight: 400;
  font-size: 14px;
  padding-left: 15px;
  border: 1px solid #a4bccf;
  color: #09131B;
  border-radius: 8px;
  height: 48px;
  margin-top: 20px;
  background:  rgba(255, 255, 255, 0.3);
}

.calendar .selectLabel {
  margin-top: -11px !important;
}

.calendar .error {
  font-family: 'Montserrat', serif;
  font-weight: 400;
  font-size: 12px;
  color: red;
  position: absolute;
  margin-top: -2px;
}



@media screen and (min-width: 576px) and (max-width: 767px) {
  .calendar form input {
    margin-top: 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .calendar form input {
    margin-top: 25px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .calendar form input {
    margin-top: 25px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .calendar form input {
    margin-top: 25px;
  }
}

@media screen and (min-width: 1400px) {
  .calendar form input {
    margin-top: 25px;
  }
}
