.orderConfirmedModal .starGreen {
  margin-top: 8px;
}

.orderConfirmedModal .starBlack {
  position: absolute;
  margin-top: -8px;
}

.orderConfirmedModal h2 {
  font-family: Montserrat, serif;
  font-size: 24px;
  font-weight: 600;
  margin-top: 10px;
}

.orderConfirmedModal p {
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
}
