.referrals {
  min-height: 100vh;
}

.referrals .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
}

.referrals .header .title {
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: #232531;
}

.referrals .header .rightEmptyBlock {
  width: 40px;
}

.referrals img {
  cursor: pointer;
}

.referrals .banner {
  background: #FFFFFF;
  box-shadow: 0px 24px 56px 0px #3E7EDD33;
  border-radius: 10px;
  margin-top: 25px;
  margin-bottom: -8px;
}

.referrals .banner h1 {
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  padding-top: 16px;
}

.referrals .banner p {
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #7B8D9C;
  margin-top: -2px;
  padding-bottom: 15px;
}

.referrals .referralItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DDF0F4;
  height: 70px;
}

.referrals .referralItem .firstDiv img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.referrals .referralItem .firstDiv span {
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 500;
  color: #232531;
  margin-left: 8px;
}

.referrals .referralItem .secondDiv img {
  width: 20px;
  margin-top: -3px;
}

.referrals .referralItem .secondDiv span {
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 500;
  color: #7B8D9C;
  margin-left: 8px
}

@media screen and (min-width: 0px) and (max-width: 320px) {
  .referrals .banner h1 {
    font-size: 15px;
    padding-top: 10px;
  }

  .referrals .banner p {
    font-size: 12px;
    margin-top: -6px;
    padding-bottom: 10px;
  }


  .referrals .referralItem {
    height: 60px;
  }

  .referrals .referralItem .firstDiv img {
    width: 35px;
    height: 35px;
  }

  .referrals .referralItem .firstDiv span {
    font-size: 15px;
    margin-left: 8px;
  }

  .referrals .referralItem .secondDiv img {
    width: 18px;
    margin-top: -3px;
  }

  .referrals .referralItem .secondDiv span {
    font-size: 15px;
    margin-left: 8px
  }
}

