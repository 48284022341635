.menu .header {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
}

.menu .header .title {
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: #232531;
  margin-top: 5px;
}

.menu .header .rightEmptyBlock {
  width: 40px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .menu .header .menuImage {
    opacity: 0;
  }

  .menu .imageMenuClose {
    opacity: 0;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .menu .header .menuImage {
    opacity: 0;
  }

  .menu .imageMenuClose {
    opacity: 0;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .menu .header .menuImage {
    opacity: 0;
  }

  .menu .imageMenuClose {
    opacity: 0;
  }
}

@media screen and (min-width: 1400px) {
  .menu .header .menuImage {
    opacity: 0;
  }

  .menu .imageMenuClose {
    opacity: 0;
  }
}
