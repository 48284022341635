.tasks {
  min-height: 100vh;
}

.tasks .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
}

.tasks .header .title {
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: #232531;
}

.tasks .header .rightEmptyBlock {
  width: 40px;
}

.tasks .header img {
  cursor: pointer;
}


.tasks .tasksCont {
  margin-bottom: 30px;
}

.tasks .taskItem {
  display: flex;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: 0px 24px 56px 0px #3E7EDD33;
  border-radius: 10px;
  margin-top: 25px;
  margin-bottom: -8px;
  padding: 16px;
}

.tasks .taskItem .firstDiv h1 {
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 4px;
  color: #232531;
}

.tasks .taskItem .firstDiv p {
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 0;
  color: #7B8D9C;
}

.tasks .taskItem .secondDiv span {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: #DDF0F4;
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
}

.tasks .taskItem .secondDiv img {
  cursor: pointer;
}

.modalContent img {
  margin: auto;
  width: 72%;
  display: block;
  margin-top: 0;
}

.modalContent h2 {
  font-family: Montserrat, serif;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-top: 16px;
}

.modalContent p {
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  width: 80%;
  margin: auto;
  margin-top:5px;
}

.modalContent button {
  display: block;
  margin-top: 18px;
  width: 100%;
  font-family: 'Montserrat', serif;
  font-weight: 700;
  font-size: 15px;
  border: none;
  height: 56px;
  background: #647bf0;
  color: white;
  border-radius: 8px;
  box-shadow: 0 24px 56px 0 #3E7EDD66;
}
