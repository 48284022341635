.schedule {
  margin-bottom: 42px;
}

.schedule h2 {
  font-family: 'Montserrat', serif;
  font-weight: 500;
  font-size: 16px;
  color: #232531;
  margin-top: 20px;
  margin-bottom: -8px;
}

.schedule ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.schedule ul li {
  margin: 0;
  padding: 0;
}

.schedule ul p {
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 500;
  color: #232531;
  margin-top: 35px;
  margin-bottom: -7px;
  display: inline-block;
  margin-right: 20px;
}

.schedule .inputHolder {
  height: 48px;
  width: 30%;
  margin-top: 0;
  max-width: 116px;
}

.schedule .back1:after {
  background: #e9f9ff
}

.schedule .back2:after {
  background: #e5f6ff;
}

.schedule .error  {
  position: absolute;
  font-family: Montserrat, serif;
  font-size: 12px;
  font-weight: 500;
  color: red;
  margin-top: 8px;
}

.schedule .scheduleItem {
  display: flex;
  justify-content: space-between;

}

.schedule .rest {
  margin-top: 30px;
  display: inline-block;
}

.schedule .rest input {
  opacity: 0;
  width: 0 !important;
  height: 0 !important;
}

.schedule .rest label {
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 500;
}

.schedule .rest label::before {
  content: '.';
  color: #e2f9fe;
  text-align: center;
  width: 18px;
  height: 18px;
  border: 2px solid #7B8D9C;
  border-radius: 1px;
  display: inline-block;
  line-height: 15px;
  margin-right: 8px;
}

.checkboxCustom:checked + .checkboxCustomLabel::before {
  content: '\2713';
  color: #647BF0;
  border: 2px solid #647BF0
}

.checkboxCustom:checked + .checkboxCustomLabel {
  font-weight: 700;
  color: #647BF0
}

.schedule .before400None {
  display: none;
}

.schedule .after400Block {
  display: inline-block;
}

@media  screen and (max-width: 400px) {
  .before400None {
    display: inline-block !important;
  }

  .after400Block {
    display: none !important;
  }

  .schedule .scheduleItem {
    display: flex;
    justify-content: start;
    margin-bottom: 12px;
  }

  .schedule .scheduleItem div:first-child {
    margin-right: 10px;
  }

  .schedule .inputHolder {
    width: 100px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .schedule .scheduleItem {
    justify-content: start;
    margin-top: -10px;
  }

  .schedule .scheduleItem div {
    margin-right: 18px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .schedule .scheduleItem {
    justify-content: start;
    margin-top: -10px;
  }

  .schedule .scheduleItem div {
    margin-right: 18px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .schedule .scheduleItem {
    justify-content: start;
    margin-top: -10px;
  }

  .schedule .scheduleItem div {
    margin-right: 18px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .schedule .scheduleItem {
    justify-content: start;
    margin-top: -10px;
  }

  .schedule .scheduleItem div {
    margin-right: 18px;
  }
}

@media screen and (min-width: 1400px) {
  .schedule .scheduleItem {
    justify-content: start;
    margin-top: -10px;
  }

  .schedule .scheduleItem div {
    margin-right: 18px;
  }
}
