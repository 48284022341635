.orders {
  min-height: 100vh;
}

.orders .order {
  margin-top: 20px;
  box-shadow: 0px 24px 56px 0px #3E7EDD33;
  background: #FFFFFF;
  border-radius: 10px;
}

.orders .orderHead {
  background: #FFFFFF;
  padding: 14px;
  border-bottom: 2px solid #D3EDFD;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.orders .orderHead .topHolder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orders .orderHead .topHolder .priceTitle {
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 400;
  color: #7B8D9C;
}

.orders .orderHead .topHolder .orderState {
  font-family: Montserrat, serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  border-radius: 40px;
  padding: 6px 14px;
}

.orders .orderHead .topHolder .paid {
  color: #0c0cff;
  background: #9bc3ff;
}

.orders .orderHead .topHolder .initial {
  color: #343a40;
  background: #c0c0c0;
}

.orders .orderHead .topHolder .canceled {
  color: #cf1e1e;
  background: #ffcaca;
}

.orders .orderHead .topHolder .completed {
  color: #087A42;
  background: #5DD69B;
}

.orders .orderHead .topHolder .accepted {
  color: #c5a313;
  background: #f9ecb3;
}

.order .orderHead p {
  font-family: Montserrat, serif;
  font-size: 20px;
  font-weight: 600;
  color: #232531;
  margin-bottom: 0;
  margin-top: 5px;
}

.orders .orderSubHead {
  background: #FFFFFF;
  padding: 18px 16px;
  border-bottom: 2px solid #D3EDFD;
  display: flex;
  justify-content: space-between;
}

.orders .orderSubHead span {
  display: block;
}

.orders .orderSubHead .span1 {
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 400;
  color: #7B8D9C;
  margin-bottom: 4px;
}

.orders .orderSubHead .div1 .span2 {
  font-family: Montserrat, serif;
  font-size: 20px;
  font-weight: 600;
  color: #232531;
}

.orders .orderSubHead .div1 .span3 {
  font-family: Montserrat, serif;
  font-size: 12px;
  font-weight: 400;
  color: #7B8D9C;
}

.orders .orderSubHead .div2 .span2 {
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 500;
  color: #232531;
}


.orders .orderBody {
  background: #FFFFFF;
  padding: 14px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.orders .orderBody span {
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 400;
  color: #7B8D9C;
  margin-bottom: 4px;
}

.orders .orderBody p {
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

.orders .orderBody p:last-child {
  margin-bottom: 0;
}

.bold {
  font-weight: 500 !important;
}

.orders .emptyCont {
  margin-top: 20vh;
}
