.service {
  min-height: 100vh;
}

.service .content {
  min-height: calc(100vh - 170px);
}

.service .headCont {
  display: flex;
  width: 100%;
}

.service .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
}

.service .head .headItem span {
  font-family: 'Montserrat', serif;
  font-weight: 500;
  font-size: 16px;
  color: #232531;
}

.service input, .service textarea {
  width: 100%;
  font-family: 'Montserrat', serif;
  font-weight: 400;
  font-size: 14px;
  padding-left: 15px;
  color: #09131B;
  border-radius: 8px;
  border: 1px solid #a4bccf;
  height: 48px;
  margin-top: 20px;
  background:  rgba(255, 255, 255, 0.3);
}

.service textarea {
  padding: 12px 15px;
  resize: none;
  overflow: hidden;
  min-height: 48px !important;
}

.service .errorInput {
  border: 1px solid red;
  margin-bottom: 5px;
}

.service .specializationNotFound {

}

.service .specializationNotFound h3 {
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #232531;
  margin-top: 15px;
}

.service .specializationNotFound p {
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: #647BF0;
}

.service .emptyError {
  color: red;
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
}

.service .ssError {
  color: red;
  font-family: Montserrat, serif;
  font-size: 11px;
  position: absolute;
  margin-top: -3px;
}

.service .specializations {
  position: relative;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 15px;
}

.service .specializations .specialization {
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 500;
  color: #647BF0;
  background: #647BF01A;
  padding: 12px 16px;
  border-radius: 40px;
  margin-right: 10px;
  display: inline-block;
  margin-bottom: 10px;
}

.service .selectedSpecialization {
  border-top: 2px solid #D3EDFD;
  padding-top: 16px;
  margin-top: 16px;
}

.service .selectedSpecializations .headCont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.service .selectedSpecializations .headCont .serviceCont {
  display: inline-block;
  background: #5CE7A3;
  border-radius: 40px;
  height: 44px;
  max-width: 90vw;
  margin-right: 10px;
}

.service .selectedSpecializations .headCont .serviceCont span {
  display: inline-block;
  overflow-x: scroll;
  white-space: nowrap;
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 600;
  color: #087A42;
  line-height: 44px;
  margin-left: 16px;
  max-width: calc(100% - 56px);
}

.service .selectedSpecializations .headCont .serviceCont span::-webkit-scrollbar {
  display: none;
}

.service .selectedSpecializations .headCont .serviceCont img {
  margin-left: 12px;
  margin-top: -35px;
  margin-right: 16px;
}

.service .selectedSpecializations .headCont .addServiceCont {
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 700;
  color: #647BF0;
  margin-top: 8px;
  display: block;
}

.service .selectedSpecializations .headCont .addServiceCont img {
  margin-right: 8px;
}

.service .selectedSpecializations .bodyCont {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.service .selectedSpecializations .bodyCont .bodyContLeft{
  width: 70%;
  display: inline-block;
}

.service .selectedSpecializations .bodyCont .serviceName {

}

.service .selectedSpecializations .bodyCont .servicePrice {
  width: 55%;
  display: inline-block;
}

.service .selectedSpecializations .bodyCont .serviceDuration {
  width: 42%;
  margin-left: 3%;
  display: inline-block;
}

.service .selectedSpecializations .bodyCont img {
  display: inline-block;
  margin-top: 20px;
  height: 40px;
}

.service .nextButton {
  position: sticky;
  bottom: 20px;
  z-index: 1;
  display: block;
  margin-top: 25px;
  width: 100%;
  font-family: 'Montserrat', serif;
  font-weight: 700;
  font-size: 15px;
  border: none;
  height: 56px;
  background: #647bf0;
  color: white;
  border-radius: 8px;
  box-shadow: 0 24px 56px 0 #3E7EDD66;
  margin-bottom: 30px;
  transition: opacity 0.6s;
  opacity: 1;
}

.service .disabled {
  opacity: 0.7;
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .service input, .service textarea {
    margin-top: 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .service input, .service textarea {
    margin-top: 25px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .service input, .service textarea {
    margin-top: 25px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .service input, .service textarea {
    margin-top: 25px;
  }
}

@media screen and (min-width: 1400px) {
  .service input, .service textarea {
    margin-top: 25px;
  }
}
