.taskShow {
  min-height: 100vh;
}

.taskShow .head {
  padding-top: 30px;
}

.taskShow h1 {
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  margin-top: 24px;
  margin-bottom: 40px
}

.taskShow .optionsCont {
  min-height: calc(100vh - 280px);
  margin-top: 40px
}

.taskShow .optionItem {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.taskShow .optionItem span {
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.taskShow .optionItem .checked {
  font-weight: 600;
}

.taskShow .optionItem img {

}

.taskShow .AddButton {
  display: block;
  margin-top: 35px;
  margin-bottom: 25px;
  width: 100%;
  font-family: 'Montserrat', serif;
  font-weight: 700;
  font-size: 15px;
  border: none;
  height: 56px;
  background: #647bf0;
  color: white;
  border-radius: 8px;
  box-shadow: 0 24px 56px 0 #3E7EDD66;
}

.taskShow .disabled {
  opacity: 0.6;
}
