.companyRequest {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.companyRequest .headItem {
  position: absolute;
  top: 35px;
}

.companyRequest .smileBlue {
  position: absolute;
  right: 0;
  top: 0;
  width: 60vw;
  max-width: 258px;
}

.companyRequest .smileGreen {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60vw;
  max-width: 287px;
}

.companyRequest .smileYellow {
  position: absolute;
  right: 0;
  bottom: 12vh;
  width: 50vw;
  max-width: 239px;
}

.companyRequest .starCont {
  margin-top: 25vh;
}

.companyRequest .starCont img:last-child {
  margin-top: -43px;
  margin-left: 2vw;
}

.companyRequest .terminovo {
  margin-top: 22px;
}

.companyRequest p {
  margin-top: 22px;
  font-family: Montserrat,serif;
  font-size: 18px;
  font-weight: 400;
  color: #232531;
  z-index: 1;
  position: relative;
  max-width: 500px;
}

.companyRequest .starBlack {
  position: absolute;
  display: block;
  margin-top: 45px;
  left: 48%;
  transform: translateX(-50%);
}

@media screen and (max-height: 840px) {
  .companyRequest .smileBlue {
    width: 52vw;
  }

  .companyRequest .smileGreen {
    width: 50vw;
  }

  .companyRequest .smileYellow {
    width: 50vw;
    bottom: 5vh;
  }

  .companyRequest .starBlack {
    margin-top: 25px;
  }
}
