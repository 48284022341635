.profile {
  min-height: 100vh;
}

.profile .content {
  min-height: calc(100vh - 170px);
}

.profile .headCont {
  display: flex;
  width: 100%;
}

.profile .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
}

.profile .head .logo {
  width: 35px;
}

.profile .head .headItem span {
  font-family: 'Montserrat', serif;
  font-weight: 500;
  font-size: 16px;
  color: #232531;
}

.profile p {
  font-family: 'Montserrat', serif;
  font-weight: 400;
  font-size: 18px;
  color: #09131B;
  margin-top: 10px;
}

.profile form input, .profile form select {
  width: 100%;
  font-family: 'Montserrat', serif;
  font-weight: 400;
  font-size: 14px;
  padding-left: 15px;
  border: 1px solid #a4bccf;
  color: #09131B;
  border-radius: 8px;
  height: 48px;
  margin-top: 20px;
  background: transparent;
}

.profile form select {
  appearance: none;
}

.profile .error  {
  font-family: 'Montserrat', serif;
  font-weight: 400;
  font-size: 12px;
  color: red !important;
}

.profile .addressQuote {
  font-family: Montserrat, serif;
  font-size: 12px;
  font-weight: 400;
  color: #7B8D9C;
  margin-top: 4px;
  margin-left: 17px;
  width: 85%;
}

.small {
  width: 30px;
}

.profile .nextButton {
  position: sticky;
  bottom: 20px;
  z-index: 1;
  display: block;
  margin-top: 25px;
  width: 100%;
  font-family: 'Montserrat', serif;
  font-weight: 700;
  font-size: 15px;
  border: none;
  height: 56px;
  background: #647bf0;
  color: white;
  border-radius: 8px;
  box-shadow: 0 24px 56px 0 #3E7EDD66;
  margin-bottom: 30px;
}

.profile .disabledButton {
  background: #8CA5B7;
}

.profile .photoCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 10px;
}

.profile .defaultImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  border: 1px solid #A4BCCF;
  font-size: 40px;
  text-align: center;
  line-height: 88px;
}

.profile .image {
  display: block;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  border: 1px solid #A4BCCF;
}

.profile .imageLabel img {
  height: 24px;
  margin-top: -3px;
  cursor: pointer;

}

.profile .imageLabel span {
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: #647BF0;
  margin-left: 7px;
  cursor: pointer;
}

.profile .imageLabel p {
  position: absolute;
  font-family: Montserrat, serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  color: #7B8D9C;
  margin-top: 5px;
  margin-bottom: 0;
}

.profile .typeSwitcher {
  display: flex;
  justify-content: space-evenly;
  padding-top: 30px;
}

.profile .typeSwitcher button.active {
  font-weight: 700;
  color: #647BF0;
  background: #CCE5FE;
}

.profile .typeSwitcher button {
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  background: #9EA0AD1F;
  border: none;
  border-radius: 8px;
  padding: 5px 0px;
  width: 48%;
}

@media screen and (max-height: 530px) {
  .profile p {
    font-size: 17px;
    margin-bottom: 8px;
  }

  .profile form input, .profile form select {
    font-size: 13px;
    height: 46px;
  }

  .specializations .specialization, .selectedSpecializations .selectedSpecialization {
    font-size: 14px;
    padding: 12px 22px;
  }

  .profile .nextButton {
    font-size: 14px;
    height: 50px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .profile .photoCont {
    justify-content: unset;
  }

  .profile .photoCont .imageLabel {
    margin-left: 30px;
  }

  .profile form input, .profile form select {
    margin-top: 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .profile .photoCont {
    justify-content: unset;
  }

  .profile .photoCont .imageLabel {
    margin-left: 25px;
  }

  .profile form input, .profile form select {
    margin-top: 25px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .profile .photoCont {
    justify-content: unset;
  }

  .profile .photoCont .imageLabel {
    margin-left: 30px;
  }

  .profile form input, .profile form select {
    margin-top: 25px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .profile .photoCont {
    justify-content: unset;
  }

  .profile .photoCont .imageLabel {
    margin-left: 35px;
  }

  .profile form input, .profile form select {
    margin-top: 25px;
  }
}

@media screen and (min-width: 1400px) {
  .profile .photoCont {
    justify-content: unset;
  }

  .profile .photoCont .imageLabel {
    margin-left: 35px;
  }

  .profile form input, .profile form select {
    margin-top: 25px;
  }
}
