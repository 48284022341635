.confirmModal .nextButton{
    display: block;
    width: 100%;
    font-family: 'Montserrat', serif;
    font-weight: 700;
    font-size: 15px;
    border: none;
    background: #647bf0;
    color: white;
    border-radius: 8px;
    box-shadow: 0 24px 56px 0 #3E7EDD66;
    height: 56px;
    margin-top: 20px;
}

.confirmModal h2 {
    font-family: 'Montserrat', serif;
    font-weight: 500;
    font-size: 24px;
    color: #09131B;
    margin-top: 35px;
}

@media screen and (max-width: 400px) {
  .confirmModal .nextButton {
    font-size: 14px;
    height: 48px;
    margin-top: 16px;
  }

  .confirmModal h2 {
    margin-top: 25px;
    font-size: 18px;
  }
}
