@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Thin.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
body {
  /*background: linear-gradient(to bottom right, #ECFFFD, #CBE8FF);*/
  /*background: linear-gradient(in oklab, #ECFFFD, #CBE8FF);*/
  background: linear-gradient(346.75deg, #CBE8FF -71.58%, #ECFFFD 96.22%);
}

input:focus, textarea:focus {
  outline: none;
}

.textOnInput {
  position: relative;
}

.textOnInput label:after {
  content: " ";
  background: #edfcfe;
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 10px;
  z-index: -1;
}

.textOnInput label {
  position: absolute;
  margin-left: 15px;
  margin-top: 9px;
  padding: 2px 4px;
  z-index: 1;
  font-family: Montserrat, serif;
  font-size: 12px;
  font-weight: 400;
  color: #7B8D9C;
  display: inline-block;
}

.disabledInput {
  color: #A4BCCF !important;
}

.pressedButton {
  transition: background 0.3s
}

.pressedButton:active {
  background: #656fa8 !important;
}

.animated-item-enter {
  opacity: 0;
}
.animated-item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.animated-item-exit {
  opacity: 1;
}
.animated-item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

iframe[name="helpcrunch-iframe"] {
  transition: transform 0.2s linear;
}

@media screen and (min-width: 360px) {
  .container {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}

@media (min-width: 726px) {
  .pressedButton:hover {
    background: #6777d0;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .textOnInput label {
    margin-top: 14px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .textOnInput label {
    margin-top: 14px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .textOnInput label {
    margin-top: 14px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .textOnInput label {
    margin-top: 14px;
  }
}

@media screen and (min-width: 1400px) {
  .textOnInput label {
    margin-top: 14px;
  }
}
