.kyc {
  min-height: 100vh;
}

.kyc .headCont {
  display: flex;
  width: 100%;
}

.kyc .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
}

.kyc .head .headItem span {
  font-family: 'Montserrat', serif;
  font-weight: 500;
  font-size: 16px;
  color: #232531;
}

.kyc .head .headItem img {
  cursor: pointer;
}

.kyc .errorInput{
  border: 1px solid red;
  margin-bottom: 5px;
}

.kyc .emptyError {
  color: red;
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
}

.kyc .ssError {
  color: red;
  font-family: Montserrat, serif;
  font-size: 11px;
  position: absolute;
  margin-top: -3px;
}

.kyc .kycTitle {
    font-family: 'Montserrat', serif;
    font-weight: 600;
    font-size: 30px;
    color: #09131B;
    margin-top: 40px;
}

.kyc p {
    font-family: 'Montserrat', serif;
    font-weight: 400;
    font-size: 18px;
    color: #09131B;
    margin-top: 10px;
}

.kyc .kycTitle span {
    background-image: url('images/title-background.png');
    background-repeat: no-repeat;
    background-size: 84% 51%;
    background-position: bottom left 0;
    padding-left: 2px;
}

.kyc .photoCont {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  margin-top: 25px;
  margin-bottom: 10px;
}

.kyc .imageLabel img {
  height: 24px;
  margin-top: -3px;
}

.kyc .imageLabel span {
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: #647BF0;
  margin-left: 7px;
}

.kyc .imageLabel p {
  position: absolute;
  font-family: Montserrat, serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: #7B8D9C;
  margin-top: 5px;
  margin-bottom: 0;
}

.kyc .defaultImage {
  display: block;
  height: 88px;
  background-color: #D1E9F3;
  font-size: 40px;
  text-align: center;
  line-height: 88px;
}

.kyc .fileInputContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  background-color: #D1E9F3;
  flex-direction: column;
}

.kyc .fileAgainInputContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.kyc .fileInput {
  display: block;
  height: 100px;
}

.kyc .nextButton {
  display: block;
  margin-top: 25px;
  width: 100%;
  font-family: 'Montserrat', serif;
  font-weight: 700;
  font-size: 15px;
  border: none;
  height: 56px;
  background: #647bf0;
  color: white;
  border-radius: 8px;
  box-shadow: 0 24px 56px 0 #3E7EDD66;
  margin-bottom: 30px;
}

.kyc .uploadedImage {
  display: flex;
}
