.confirmation {

}

.confirmation .starPurple {
    margin-top: 12vh;
}

.confirmation .starBlack {
    margin-top: 5vh;
}

.confirmation .confirmTitle {
    font-family: 'Montserrat', serif;
    font-weight: 600;
    font-size: 30px;
    color: #09131B;
    margin-top: 40px;
}

.confirmation .confirmTitle span {
    background-image: url('images/title-background.png');
    background-repeat: no-repeat;
    background-size: 84% 51%;
    background-position: bottom left 0;
    padding-left: 2px;
}

.confirmation p {
    font-family: 'Montserrat', serif;
    font-weight: 400;
    font-size: 18px;
    color: #09131B;
    margin-top: 10px;

}

.confirmation input {
    width: 100%;
    font-family: 'Montserrat', serif;
    font-weight: 400;
    font-size: 14px;
    padding-left: 10px;
    border: 1px solid #a4bccf;
    color: #09131B;
    border-radius: 8px;
    height: 48px;
    margin-top: 12px;
}
.confirmation .error {
    font-family: 'Montserrat', serif;
    font-weight: 400;
    font-size: 14px;
    color: red;
    position: absolute;
}

.confirmation .confirmButton{
    display: block;
    margin-top: 25px;
    width: 100%;
    font-family: 'Montserrat', serif;
    font-weight: 700;
    font-size: 15px;
    border: none;
    height: 56px;
    background: #647bf0;
    color: white;
    border-radius: 8px;
    box-shadow: 0 24px 56px 0 #3E7EDD66;
}

.confirmation .resendButton {
    display: block;
    margin-top: 20px;
    margin-bottom: 25px;
    background: transparent;
    color: #647BF0;
    border: solid 1px;
    border-radius: 8px;
    box-shadow: none;
    font-family: 'Montserrat', serif;
    font-weight: 700;
    font-size: 15px;
    height: 56px;
    padding: 0px 15px 0px 15px;
    width: 100%;
}

.confirmation  .disabledButton {
    color: #8CA5B7;
}

@media screen and (max-height: 530px) {
    .confirmation .starPurple, .confirmation .starBlack {
        display: none;
    }

    .confirmation .confirmTitle {
        font-size: 29px;
        margin-top: 30px;
    }

    .confirmation p {
        font-size: 17px;
        margin-bottom: 5px;
    }

    .confirmation input {
        font-size: 13px;
        margin-bottom: 15px;

    }

    .confirmation .confirmButton, .confirmation .resendButton {
        font-size: 14px;
        margin-top: 15px;
    }
}
