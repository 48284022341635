.login {
  min-height: 100vh;
  min-width: 260px;
}

.login .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
}

.login .head .logo {
  width: 35px;
}

.login .headItem:has(.logo) {
  text-align: center;
}

.login .head .headItem {
  width: 65px;
}

.login .error  {
  color: red;
}

.login .starPurple {
 margin-top: 12vh;
}

.login .starBlack {
 margin-top: 5vh;
}

.login .loginTitle {
  font-family: 'Montserrat', serif;
  font-weight: 600;
  font-size: 30px;
  color: #09131B;
  margin-top: 40px;
}

.login .loginTitle span {
  background-image: url('images/title-background.png');
  background-repeat: no-repeat;
  background-size: 84% 51%;
  background-position: bottom left 0;
  padding-left: 2px;
}

.login p {
  font-family: 'Montserrat', serif;
  font-weight: 400;
  font-size: 18px;
  color: #09131B;
  margin-top: 10px;
}

.login input {
  width: 100%;
  font-family: 'Montserrat', serif;
  font-weight: 400;
  font-size: 14px;
  padding-left: 10px;
  border: 1px solid #a4bccf;
  color: #09131B;
  border-radius: 8px;
  height: 48px;
  margin-top: 12px;
}

.login  .nextButton {
  display: block;
  margin-top: 25px;
  margin-bottom: 25px;
  width: 100%;
  font-family: 'Montserrat', serif;
  font-weight: 700;
  font-size: 15px;
  border: none;
  height: 56px;
  background: #647bf0;
  color: white;
  border-radius: 8px;
  box-shadow: 0 24px 56px 0 #3E7EDD66;
}

@media screen and (max-height: 530px) {
  .login .starPurple, .login .starBlack {
    display: none;
  }

  .login .loginTitle {
    font-size: 29px;
    margin-top: 40px;
  }

  .login p {
    font-size: 17px;
  }

  .login input {
    font-size: 13px;
  }

  .login .nextButton {
    font-size: 14px;
  }
}
